var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header__title"},[_vm._v("欢迎登录")]),_c('div',{staticClass:"notice-line"},[_c('div',[_vm._v("还没有账号？")]),_c('div',{staticClass:"main",on:{"click":_vm.toRegistry}},[_vm._v("立即注册")])]),_c('van-form',{staticClass:"form",on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"autocomplete":"off","clearable":"","name":"账号","label":"","placeholder":"请输入账号","rules":[
          {
            required: true,
          },
        ]},model:{value:(_vm.form.loginname),callback:function ($$v) {_vm.$set(_vm.form, "loginname", $$v)},expression:"form.loginname"}}),_c('van-field',{attrs:{"autocomplete":"off","type":"password","center":"","clearable":"","name":"密码","label":"","placeholder":"请输入密码","rules":[
          { required: true,
          }
        ]},model:{value:(_vm.form.loginpwd),callback:function ($$v) {_vm.$set(_vm.form, "loginpwd", $$v)},expression:"form.loginpwd"}}),_c('div',{staticClass:"notice-line"},[_c('van-checkbox',{attrs:{"checked-color":"#00B7FF","icon-size":"16px","shape":"square"},model:{value:(_vm.isRememberPassword),callback:function ($$v) {_vm.isRememberPassword=$$v},expression:"isRememberPassword"}},[_vm._v("记住密码")]),_vm._v(" "),_c('div',{staticClass:"forget",on:{"click":_vm.goMore}},[_vm._v("忘记密码？")])],1),_c('div',{staticStyle:{"margin-top":"2vh"}},[_c('van-button',{staticStyle:{"height":"48px","font-size":"20px","border":"none","background-image":"linear-gradient(0deg, #6024FF 1%, #B661FF 100%)","box-shadow":"inset 0 -2px 4px 1px rgba(255,255,255,0.30)","border-radius":"24px"},attrs:{"block":"","loading":_vm.loading,"type":"info","loading-text":"登录中...","native-type":"submit"}},[_vm._v("登录")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }