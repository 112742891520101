<!--
 * @Author: Xushijie 87086132@qq.com
 * @Date: 2024-01-06 12:11:55
 * @LastEditors: Xushijie 87086132@qq.com
 * @LastEditTime: 2024-02-17 12:24:27
 * @FilePath: \mall\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="key" />

    <tabbar v-if="$route.meta.showTab" />
  </div>
</template>

<script>
import Tabbar from './components/Tabbar'

export default {
  components: {
    Tabbar
  },
  computed: {
    key() {
      return this.$route.fullPath
    }
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>
<style scoped>
  #app {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    background: #151e34;
    /* background: #f5f5f5; */
  }
</style>
