// import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
  article: {
    text: 'Good morning, the weather is really nice today. Wishing you a wonderful day. Study hard and make progress every day.'
  },
  placeholder: 'Please select',
  HelloWorld: {
    open: {
      button: 'Click to open Dialog'
    },
    title: 'This is the English title',
    cancel: 'Cancel',
    confirm: 'Confirm',
    close: {
      title: "Are you sure it's closed?",
      cancel: 'Cancel',
      confirm: 'Confirm'
    },
    tips: 'tips'
  }
  // ...enLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
export default en
