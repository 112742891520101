// import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
  article: {
    text: '早上好，今天天气真好，祝你有个美好的一天。好好学习，天天向上。'
  },
  placeholder: '请选择',
  HelloWorld: {
    open: {
      button: '点击打开对话框'
    },
    title: '这是中文标题',
    cancel: '返回',
    confirm: '确认',
    close: {
      title: '确认关闭？'
    },
    tips: '提示'
  }
  // ...zhLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
// 导出
export default cn
