<template>
  <div class="customer-service-pop">
    <!-- <div class="bg-icon" /> -->
    <div class="list">
      <div class="item">
        <div class="left">
          <div class="icon1" />
          <div>在线客服</div>
        </div>
        <div class="right" @click="openNew(onlineurl)">点击咨询
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="icon2" />
          <div>Telegram</div>
        </div>
        <div class="right" @click="openNew(telgramurl)">点击咨询
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="icon3" />
          <div>官方频道</div>
        </div>
        <div class="right" @click="openNew(officialurl)">点击咨询
        </div>
      </div>
      <div class="item">
        <div class="left">
          <div class="icon4" />
          <div>WhatsApp</div>
        </div>
        <div class="right" @click="openNew(whatsappurl)">点击咨询
        </div>
      </div>
    </div>
    <van-icon class="close-icon" name="cross" @click="$emit('close')" />
  </div>
</template>
<script>
import { getServerUrl } from '@/api/user'
export default {
  data() {
    return {
      telgramurl: '',
      officialurl: '',
      onlineurl: '',
      whatsappurl: ''
    }
  },
  async created() {
    const res = await getServerUrl()
    this.telgramurl = res.telgramurl
    this.officialurl = res.officialurl
    this.onlineurl = res.onlineurl
    this.whatsappurl = res.whatsappurl
  },
  methods: {
    openNew(url) {
      window.open(url)
    }
  }
}
</script>
<style lang="less" >
.customer-service-pop{
    height: 800px;
    width: 560px;
    padding-bottom: 10px;
    // background: #202a41;
    background: url(~@/assets/portal/customerService/bg_弹窗.png) no-repeat 50%;
    background-size: 100% 100%;
    border-radius: 28px;
    .close-icon{
        color: #fff;
        font-size: 48px;
        position: absolute;
        // top: 24px;
        // right: 24px;
        bottom: -60px;
        left: 250px;
        z-index: 9;
    }
    .bg-icon {
        position: absolute;
        top: 0;
        right: 20px;
        width: 680px;
        height: 560px;
        background: url(~@/assets/portal/customerService/customer_service_bg.png) no-repeat 50%;
        background-size: 100% 100%;
        z-index: 2;
    }
    .icon {
        height: 360px;
    }
    .list {
        position: relative;
        width: 450px;
        z-index: 99;
        top: 500px;
        margin: auto;
        .item {
            margin: 0 auto;
            margin-bottom: 10px;
            // padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 400px;
            height: 60px;
            border-bottom: 1px solid rgba(255,255,255,0.12);
            .left {
                display: flex;
                align-items: center;
                font-family: PingFangSC-Medium;
                font-size: 28px;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;
                .icon1 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/在线客服.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon2 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/Telegram.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon3 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/官方频道.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon4 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/WhatsAPP.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
            }
            .right {
                height: 48px;
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: linear-gradient(-41deg, #2653E6 0%, #ED62FC 100%);
                border-radius: 24px;
                font-family: PingFangSC-Medium;
                font-size: 28px;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;
                .btn_jinru {
                    margin-left: 20px;
                    width: 14px;
                    height: 20px;
                    background: url(~@/assets/portal/customerService/btn_jinru2.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
            }
        }
        .item:last-child{
          border-bottom: none;
        }
    }
}
.van-popup {
    overflow-y: initial;
    background-color: transparent;
}
</style>
