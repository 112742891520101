var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registry"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header__title"},[_vm._v("注册")]),_c('div',{staticClass:"notice-line"},[_c('div',[_vm._v("已有账号？")]),_c('div',{staticClass:"main",on:{"click":_vm.toLogin}},[_vm._v("立即登录")])]),_c('van-form',{staticClass:"form",on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"autocomplete":"off","clearable":"","name":"账号","label":"","placeholder":"请输入账号","rules":[
          {
            required: true,
          }
        ]},model:{value:(_vm.form.loginname),callback:function ($$v) {_vm.$set(_vm.form, "loginname", $$v)},expression:"form.loginname"}}),_c('van-field',{attrs:{"autocomplete":"off","type":"password","center":"","clearable":"","name":"密码","label":"","placeholder":"请输入密码","rules":[
          { required: true,
          },{pattern:/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$/
             ,message:'请输入6至20位小写字母, 大写字母和数字的组合',trigger:'onBlur'}
        ]},model:{value:(_vm.form.loginpwd),callback:function ($$v) {_vm.$set(_vm.form, "loginpwd", $$v)},expression:"form.loginpwd"}}),_c('van-field',{attrs:{"autocomplete":"off","type":"password","center":"","clearable":"","name":"密码","label":"","placeholder":"请再次输入密码","rules":[
          { required: true
          },{pattern:/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$/
             ,message:'请输入6至20位小写字母, 大写字母和数字的组合',trigger:'onBlur'}
        ]},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}}),_c('van-field',{attrs:{"autocomplete":"off","type":"text","center":"","clearable":"","name":"邀请码","label":"","placeholder":"请输入邀请码(选填)","disabled":_vm.hasCode,"rules":[
          { required: false
          }
        ]},model:{value:(_vm.form.yqcode),callback:function ($$v) {_vm.$set(_vm.form, "yqcode", $$v)},expression:"form.yqcode"}}),_c('div',{staticStyle:{"margin-top":"2vh"}},[_c('van-button',{staticStyle:{"height":"48px","font-size":"20px","border":"none","background-image":"linear-gradient(0deg, #6024FF 1%, #B661FF 100%)","box-shadow":"inset 0 -2px 4px 1px rgba(255,255,255,0.30)","border-radius":"24px"},attrs:{"block":"","loading":_vm.loading,"type":"info","loading-text":"注册中...","native-type":"submit"}},[_vm._v("注册")])],1)],1),_c('div',{staticClass:"notice-line"},[_c('div',{staticClass:"main",staticStyle:{"margin":"auto"},on:{"click":function($event){return _vm.$emit('showService')}}},[_vm._v("联系客服")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }